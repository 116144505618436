<template>
<b-card v-if="branchs" no-body class="card-company-table">
    <b-table :items="branchs" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(adminUser)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.adminUser != null
            ? data.item.adminUser.firstName + " " + data.item.adminUser.lastName
            : ""
        }}</span>
        </template>

        <!-- Campaign -->
        <template #cell(branchType)="data">
            <b-badge pill variant="light-success">
                {{ data.item.branchType == 0 ? "12": "8"}}
            </b-badge>
        </template>

        <template #cell(actions)="data">
            <b-row name="ddd">
                <b-button-group>
                    <b-button size="sm" variant="outline-primary" class="btn-tour-skip" @click="menusPanelOpen(data.item)">
                        <span class="align-middle">Menu</span>
                    </b-button>
                    <b-button size="sm" variant="outline-secondary" class="btn-tour-skip" @click="studentMenusPanelOpen(data.item)">
                        <span class="align-middle">Öğrenci Menu</span>
                    </b-button>
                    <b-button size="sm" variant="outline-warning" class="btn-tour-skip" @click="editBranch(data.item)">
                        <span class="align-middle">Düzenle</span>
                    </b-button>
                </b-button-group>
            </b-row>
        </template>

    </b-table>
    <!--Open Add Branch-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="menusPanel">

        <b-form class="p-2" @submit.prevent>
            <!-- Code -->
            <b-form-group label="Menüler"></b-form-group>

            <b-form-group v-for="item in navMenuItems.filter(x=>x.permission=='ALL' || x.permission=='BRANCHADMIN')" :key="item.route" id="remember-me" label-for="from">
                <b-form-checkbox v-model="branchMenusCheck" :name="item.route" :value="item.route">
                    {{item.title}}
                </b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <b-button @click="saveBranchMenus">Kaydet</b-button>
            </b-form-group>

        </b-form>
    </b-sidebar>
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="studentMenusPanel">

        <b-form class="p-2" @submit.prevent>
            <!-- Code -->
            <b-form-group label="Menüler"></b-form-group>

            <b-form-group v-for="item in navStudentMenuItems.filter(x=>x.permission=='ALL'  )" :key="item.route" id="remember-me" label-for="from">
                <b-form-checkbox v-model="branchStudentMenusCheck" :name="item.route" :value="item.route">
                    {{navtr[item.title]}}
                </b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <b-button @click="saveBranchStudentMenus">Kaydet</b-button>
            </b-form-group>

        </b-form>
    </b-sidebar>
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Şube Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- Code -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="addBranchRequest.code" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="addBranchRequest.name" />
                </b-form-group>

                <!-- Description -->
                <b-form-group label="Açıklaması" label-for="message">
                    <b-form-textarea id="message" rows="12" v-model="addBranchRequest.definition" />
                </b-form-group>

                <!-- BranchType -->
                <b-form-group label="Tip" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(type) => type.id" :options="types" v-model="addBranchRequest.branchType" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addBranch">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Şube Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- Code -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="editBranchRequest.code" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="editBranchRequest.name" />
                </b-form-group>

                <!-- Description -->
                <b-form-group label="Açıklaması" label-for="message">
                    <b-form-textarea id="message" rows="12" v-model="editBranchRequest.definition" />
                </b-form-group>

                <!-- BranchType -->
                <b-form-group label="Tip" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(type) => type.id" :options="types" v-model="editBranchRequest.branchType" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateBranch">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</b-card>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import navStudentMenuItems from '@/navigation/studentNavigation.js'
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BFormCheckbox,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BButtonGroup,
    BMediaBody,
    BMedia,
    BRow,
    BCol,
    BMediaAside
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        BButtonGroup,
        ToastificationContent,
        BCard,
        BTable,
        BFormCheckbox,
        BRow,
        BCol,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside
    },
    data() {
        return {
            navtr: {
                "Nav": {
                    "Home": "Ana Sayfa",
                    "Lesson": "Dersler",
                    "Exam": "Testler",
                    "Reps": "Tekrar İzle",
                    "Calendar": "Takvim",
                    "Target": "Hedeflerim",
                    "Missing": "Eksiklerim",
                    "Badge": "Rozetlerim",
                    "Report": "Raporlarım",
                    "ReportCard": "Karnelerim",
                    "HomeWork": "Ödevlerim",
                    "Statistic": "İstatistik",
                    "Guidance": "Rehberlik",
                    "Reply": "Soru-Cevap",
                    "Message": "Mesajlar",
                    "CDDO": "CDDO",
                    "Notability": "Notability",
                    "Profile": "Profilim",
                    "Langue": "Dili Değiştir",
                    "Theme": "Temayı Değiştir",
                    "Logout": "Çıkış Yap",
                    "ChangeProfile":"Profil Resmi Değiştirme",
                }
            },
            navMenuItems: navMenuItems,
            navStudentMenuItems: navStudentMenuItems,
            menusPanel: false,
            studentMenusPanel: false,
            selectedBranch: [],
            branchMenusCheck: [],
            branchStudentMenusCheck: [],
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Kodu' },
                { key: 'name', label: 'Adı' },
                { key: 'definition', label: 'Açıklaması' },
                { key: 'adminUser', label: 'Yönetici Kullanıcı' },
                { key: 'branchType', label: 'Şube Tipi' },
                { key: 'actions', label: 'İşlemler', thStyle: { width: '20%' } },
            ],
            branchs: [],
            types: [{
                id: 0,
                name: "12"
            }, {
                id: 1,
                name: "8"
            }],
            addPanel: false,
            editPanel: false,
            addBranchRequest: {
                code: "",
                name: "",
                definition: "",
                branchType: 0
            },
            editBranchRequest: {
                id: "",
                code: "",
                name: "",
                definition: "",
                branchType: 0
            }
        }
    },
    created() {
        this.getData();
        var navtr = this.navtr["Nav"];
        for (const key in navtr) {
            const element = navtr[key];
            this.navtr["Nav." + key] = element;
        }

    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            //console.log("Add Button Call");
            this.addPanel = true;
        });
    },
    methods: {
        async saveBranchMenus() {
            var copyNavMenus = this.navMenuItems.filter(elem => elem.permission == "ALL" || elem.permission == "BRANCHADMIN");
            var updateMenus = new Object()
            for (const key in copyNavMenus) {
                var elem = copyNavMenus[key];
                //elem.route[0].toUpperCase();
                var item = this.branchMenusCheck.find(x => x == elem.route);
                if (item == null) {
                    updateMenus[elem.route] = false;
                } else {
                    updateMenus[elem.route] = true;
                }
            }

            var _this = this;

            await this.$http.put("Branch/updateMenus", {
                branchId: this.selectedBranch.id,
                Menus: JSON.stringify(updateMenus),
            }).then(function (data) {

                _this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'İşlem',
                        icon: 'BellIcon',
                        text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                        variant: 'success'
                    },
                });
                _this.getData();
                _this.menusPanel = false;
            });
        },

        async saveBranchStudentMenus() {
            var copyNavMenus = this.navStudentMenuItems.filter(elem => elem.permission == "ALL");
            var updateMenus = new Object();
            for (const key in copyNavMenus) {
                var elem = copyNavMenus[key];
                var item = this.branchStudentMenusCheck.find(x => x == elem.route);
                if (item == null) {
                    updateMenus[elem.route] = false;
                } else {
                    updateMenus[elem.route] = true;
                }
            }

            var _this = this;

            await this.$http.put("Branch/updateStudentMenus", {
                branchId: this.selectedBranch.id,
                Menus: JSON.stringify(updateMenus),
            }).then(function (data) {

                _this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'İşlem',
                        icon: 'BellIcon',
                        text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                        variant: 'success'
                    },
                });
                _this.getData();
                _this.studentMenusPanel = false;
            });
        },

        async getData() {
            var branchs = await this.$http.get("Company/Branchs");
            this.branchs = branchs.data.data;
        },
        menusPanelOpen(branch) {
            this.selectedBranch = branch;
            if (branch.menus == "0" || branch.menus == "" || branch.menus == null) {
                var _this = this;
                this.navMenuItems.filter(function (elem) {
                    _this.branchMenusCheck.push(elem.route);
                });
            } else {
                var menus = JSON.parse(branch.menus);
                this.branchMenusCheck = [];
                for (const key in menus) {
                    const element = menus[key];
                    if (element == true) {
                        this.branchMenusCheck.push(key);
                    }
                }
            }

            this.menusPanel = true;
        },
        studentMenusPanelOpen(branch) {
            this.selectedBranch = branch;

            if (branch.studentMenus == "0" || branch.studentMenus == "" || branch.studentMenus == null) {
                var _this = this;
                this.navStudentMenuItems.filter(function (elem) {
                    _this.branchStudentMenusCheck.push(elem.route);
                });
            } else {
                var menus = JSON.parse(branch.studentMenus);
                this.branchStudentMenusCheck = [];
                for (const key in menus) {
                    const element = menus[key];
                    if (element == true) {
                        this.branchStudentMenusCheck.push(key);
                    }
                }
            }

            this.studentMenusPanel = true;
        },

        async addBranch() {
            await this.$http.post("Company/AddBranch", this.addBranchRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Bildirim',
                    icon: 'BellIcon',
                    text: 'İşlem Başarılı.',
                    variant: 'success'
                },
            });
            this.addPanel = false;
            this.getData();
        },
        editBranch(branch) {
            this.editBranchRequest = branch;
            this.editPanel = true;
        },
        async updateBranch() {
            await this.$http.put("Company/UpdateBranch/"+this.editBranchRequest.id, this.editBranchRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Bildirim',
                    icon: 'BellIcon',
                    text: 'İşlem Başarılı.',
                    variant: 'success'
                },
            });
            this.editPanel = false;
            this.getData();
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
